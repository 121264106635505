<template>
  <router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  mounted() {
    let locale = localStorage.getItem("locale")
    if (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style>

</style>

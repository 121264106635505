import Axios from "axios";
import router from "../router";
import {ElMessage, ElMessageBox} from 'element-plus'
import i18n from "../locale"


let axios = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.AUTHORIZATION = token;
    }
    const locale=localStorage.getItem("locale")
    if (locale){
        config.headers['Accept-Language']=locale
    }else {
        config.headers['Accept-Language']=process.env.VUE_APP_LOCALE
    }
    return config;
}, () => {
    ElMessage.error({message: i18n.global.t('global.errorMsg'), offset: 403});
    return new Promise(() => {
    });
})

axios.interceptors.response.use(response => {
    switch (response.data.code) {
        case 200:
            return response.data;
        case 401:
            localStorage.removeItem("token")
            router.push('/login');
            return Promise.reject(response.data.msg);
        case 403:
            return Promise.reject(response.data.msg);
        case 402:
            ElMessage.error({message: i18n.global.t('global.InsufficientBalance'), offset: 403});
            return Promise.reject(response.data.msg);
        case 429:
            ElMessageBox.alert(i18n.global.t('global.concurrentError.content'), i18n.global.t('global.concurrentError.title'), {
                confirmButtonText: i18n.global.t('global.concurrentError.ok')
            }).then(() => {
            }).catch(() => {
            })
            return Promise.reject(response.data.msg);
        case 500:
            ElMessage.error({message: i18n.global.t('global.errorMsg'), offset: 403});
            return Promise.reject(response.data.msg);
        case 501:
            ElMessage.warning({message: i18n.global.t('global.loginFailed')});
            return Promise.reject(response.data.msg);
    }
}, error => {
    ElMessage.error({message: i18n.global.t('global.errorMsg'), offset: 403});
    return Promise.reject(error);
})

export default axios

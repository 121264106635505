import {createRouter, createWebHistory} from "vue-router";

const Login = () => import("@/views/LoginPage");
const Template = () => import("@/views/TemplateSelection")
const Mode = () => import("@/views/ModeSelection")
const WorkSpace = () => import("@/views/MainWorkspace")

const MoreIdea = () => import("@/views/MoreIdea")
const Waterfall = () => import("@/components/WaterfallPage.vue")
const Usage = () => import("@/components/UsagePage.vue")

const FullResearch = () => import("@/views/FullReasearch.vue")
const FullResearchDisplay = () => import("@/components/FullResearchDisplay.vue")
const ShortVideoScript = () => import("@/views/ShortVideoScript.vue")
const ShortVideoScriptDisplay = () => import("@/components/ShortVideoScriptDisplay.vue")

const Tips = () => import("@/views/Tips.vue")
const TipsDisplay = () => import("@/components/TipsDisplay.vue")
const Explosion = () => import("@/views/Explosion.vue")
const ExplosionDisplay = () => import("@/components/ExplosionDisplay.vue")

const routes = [
    {path: '/', redirect: '/login'},
    {path: '/login', component: Login},
    {path: '/template', component: Template},
    {path: '/mode', component: Mode},
    {path: '/full/research', component: FullResearch},
    {path: '/short/video/script', component: ShortVideoScript},
    {path: '/more-idea', component: MoreIdea},
    {path: '/tips', component: Tips},
    {path: '/explosion', component: Explosion},
    {
        path: '/workspace',
        component: WorkSpace,
        children: [
            {
                path: 'new/product',
                component: Waterfall
            },
            {
                path: 'social/reputation',
                component: Waterfall
            },
            {
                path: 'imc',
                component: Waterfall
            },
            {
                path: 'usage',
                component: Usage
            },
            {
                path: 'full/research',
                component: FullResearchDisplay
            },
            {
                path: 'short/video/script',
                component: ShortVideoScriptDisplay
            },
            {
                path: 'tips',
                component: TipsDisplay
            },
            {
                path: 'explosion',
                component: ExplosionDisplay
            },
            {
                name: 'ImageGenerator',
                path: 'image-generator',
                component: () => import('@/components/ImageGenerator.vue')
            }
        ]
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router;
